.divContainerTable {
	background-color: #ffffff;
	border-radius: 0.4rem;
	padding: 1rem;
}
.inputSearch {
	width: 100%;
	background-color: #f6f4f9;
}
.divFilter {
	margin-top: 1rem;
}
.divTable {
	margin-top: 2rem;
}
